import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { NewsletterService } from '@app/core/services/newsletter.service';

import { NewsletterValueModel } from '@app/newsletter/models';
import { RDS_DIALOG_DATA, RdsDialogRef } from '@rds/angular-components';
import { NEWS_PLACEHOLDER_DATA } from '@src/assets/images/news-placeholder-base64';
import * as htmlToImage from 'html-to-image';
import { combineLatest, debounceTime, filter, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { Section } from '@app/newsletter-new/models/section-type.enum';
import { RhNewsletterForm } from '@app/newsletter-new/models/newsletter';
import {SkeletonTemplateDirective} from '@app/newsletter-new/dialogs/save-template-preview-dialog/template-skeleton.directive';

export interface SaveTemplatePreviewDialogData {
	sections?: Observable<Array<Section>>;
	form?: Observable<Partial<RhNewsletterForm>>;
}

@Component({
	selector: 'rh-save-template-preview-dialog',
	templateUrl: './save-template-preview-dialog.component.html',
	styleUrls: ['./save-template-preview-dialog.component.scss']
})
export class SaveTemplatePreviewDialogComponent implements AfterViewInit, OnDestroy {
	@ViewChild('image') image: ElementRef;
	@ViewChild('imagePlaceholder') imagePlaceholder: ElementRef;
	@ViewChild('videoPlaceholder') videoPlaceholder: ElementRef;
	private subs: SubSink = new SubSink();
	templateId: string;
	newsletterId: number;
	predefinedNewsletter: Partial<NewsletterValueModel>;
	formNewsletter: Observable<NewsletterValueModel>;

	news;
	imageData;
	isGenerating: boolean;

	isChecking = false;

	constructor(
		@Inject(RDS_DIALOG_DATA) public data: SaveTemplatePreviewDialogData,
		public rdsDialogRef: RdsDialogRef<SaveTemplatePreviewDialogComponent>,
		private newsletterService: NewsletterService,
		private cdr: ChangeDetectorRef
	) {}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngAfterViewInit(): void {
		this.subs.sink = combineLatest([this.data.form, this.data.sections])
			.pipe(
				filter(([form, sections]) => !!form && !!sections),
				debounceTime(1000)
			)
			.subscribe(([form, sections]) => {
				this.createPreview();
			});
	}

	show = false;

	createPreview() {
		this.isGenerating = true;
		htmlToImage
			.toPng(this.image.nativeElement, {
				quality: 1,
				width: this.image.nativeElement.offsetWidth,
				height: this.image.nativeElement.offsetHeight,
				canvasWidth: this.image.nativeElement.offsetWidth,
				canvasHeight: this.image.nativeElement.offsetHeight,
				imagePlaceholder: NEWS_PLACEHOLDER_DATA,
			})
			.then(async (dataUrl) => {
				const blob = this.dataURItoBlob(dataUrl);
				const file = new File([blob], 'file_name.png');
				this.newsletterService.uploadFile(file).subscribe((res) => {
					this.rdsDialogRef.close({ url: res, error: null });
				});
			})
			.catch((err) => {
				this.rdsDialogRef.close({ url: null, error: true });
			});
	}

	dataURItoBlob(dataURI) {
		var byteString = atob(dataURI.split(',')[1]);
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: mimeString });
	}
}
