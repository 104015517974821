import { Routes } from '@angular/router';
import { RoleGuard } from '@core/auth/guards/role.guard';
import { RoleNames } from '@core/enums/roles.enum';
import { environment } from '@env/environment';
import { NotFoundErrorComponent, StandardLayoutComponent } from 'src/app/layout/components';
import { onlyDesktopActivate } from './core/auth/guards/onlyDesktopActivate.guard';
import { onlyDesktopDeactivate } from './core/auth/guards/onlyDesktopDeactivate.guard';
import { GoogleAuthComponent } from './google-auth/google-auth.component';

const userPages: Routes = [
	{
		path: '',
		component: StandardLayoutComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
			},
			{
				path: 'channel/:id',
				redirectTo: 'channels/:id',
				pathMatch: 'full',
			},
			{
				path: 'channel/:id/suggestions',
				redirectTo: 'channels/:id/suggestions',
				pathMatch: 'full',
			},
			{
				path: 'channel/:id/all',
				redirectTo: 'channels/:id/all',
				pathMatch: 'full',
			},
			{
				path: 'channel/:id/archived',
				redirectTo: 'channels/:id/archived',
				pathMatch: 'full',
			},

            {
                path: 'channel/:id/info',
                redirectTo: 'channels/:id/info',
                pathMatch: 'full'
            },
            {
                path: 'channel/:id/edit',
                redirectTo: 'channels/:id/edit',
                pathMatch: 'full'
            },
            {
                path: 'catalog/preview/content/:id',
                redirectTo: 'catalog/entry/:id/info',
                pathMatch: 'full'
            },
            // MODULES CURRENTLY CLEANED
            {
                // ASSIGNEMENT: Radek
                path: 'alerts',
                loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsModule),
                canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
            {
                // ASSIGNEMENT: Radek
                path: 'content/approvals',
                loadChildren: () => import('./content-approvals/content-approvals.module').then(m => m.ContentApprovalsModule),
                canActivate: [RoleGuard, onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate],
                data: {roles: [RoleNames.ADMINISTRATOR, RoleNames.AUDIENCE_MANAGER]}
            },
            {
                // ASSIGNEMENT: Radek
                path: 'content',
                loadChildren: () => import('./content-manager/content-manager.module').then(m => m.ContentManagerModule),
                canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
            {
                // ASSIGNEMENT: Radek
                path: 'workspace',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
            {
                // ASSIGNEMENT: Radek
                path: 'generic-accounts',
                loadChildren: () => import('./generic-accounts/generic-accounts.module').then(m => m.GenericAccountsModule),
                canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
            {
                // ASSIGNEMENT: Radek
                path: 'admin',
                loadChildren: () => import('./newsboard/admin/admin.module').then(m => m.AdminModule),
                canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
            {
                // ASSIGNEMENT: Radek
                path: 'channels',
                loadChildren: () => import('./newsboard/channel/channel.module').then(m => m.ChannelModule),
                canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
            {
                // ASSIGNEMENT: Radek
                path: 'news',
                loadChildren: () => import('./newsboard/news/news.module').then(m => m.NewsModule),
                canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
            {
                // ASSIGNEMENT: Radek
                path: 'ticker',
                loadChildren: () => import('./ticker/ticker.module').then(m => m.TickerModule),
                data: {roles: [RoleNames.CONTENT_QUALITY_MANAGER, RoleNames.ADMINISTRATOR]},
                canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
            {
                // ASSIGNEMENT: Radek
                path: 'managing-events',
                loadChildren: () => import('./managing-events/managing-events.module').then(m => m.ManagingEventsModule),
                canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
            {
                path: 'sitemap',
                canActivate: [() => environment.dev],
                loadChildren: () => import('./sitemap/sitemap.module').then(m => m.SitemapModule)
            },
            {
                path: 'development',
                canActivate: [() => environment.dev],
                loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule)
            },
            {
                // ASSIGNEMENT: Radek
                path: 'role',
                data: {roles: [RoleNames.ADMINISTRATOR]},
                loadChildren: () => import('./role-assign/role-assign.module').then(m => m.RoleAssignModule),
                canActivate: [RoleGuard, onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
            {
                // ASSIGNEMENT: Radek
                path: 'intranet-coordinators',
                loadChildren: () => import('./intranet-coordinators/intranet-coordinators.module').then(m => m.IntranetCoordinatorsModule)
            },
            {
                // ASSIGNEMENT: Radek
                path: 'manage-sets',
                loadChildren: () => import('./manage-sets/manage-sets.module').then(m => m.ManageSetsModule),
                canActivate: [RoleGuard, onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate],
                data: {roles: [RoleNames.ADMINISTRATOR, RoleNames.AUDIENCE_MANAGER]}
            },
            // ASSIGNEMENT: Rafal
            {
                path: 'bookmarks',
                loadChildren: () => import('./bookmarks/bookmarks.module').then(m => m.BookmarksModule)
            },
            // ASSIGNEMENT: Rafal / Radek
            {
                path: 'settings',
                loadChildren: () => import('./user-settings/user-settings.module').then(m => m.UserSettingsModule)
            },
            // ASSIGNEMENT: Radek
            {
                path: 'catalog',
                loadChildren: () => import('./catalog-entry/catalog-entry.module').then(m => m.CatalogEntryModule),
                canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
//MODULES LEFT FOR CLEAN ACTIONS
            {
                path: 'search',
                loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
            },
            {
                path: 'list',
                loadChildren: () => import('./content-list/content-list.module').then(m => m.ContentListModule)
            },
            {
                path: 'content',
                loadChildren: () => import('./news-details/news-details.module').then(m => m.NewsDetailsModule)
            },
            {
                path: 'users',
                loadChildren: () => import('./workday-user/workday-user.module').then(m => m.WorkdayUserModule)
            },
            {
                path: 'newsletter-legacy',
                loadChildren: () => import('./newsletter/newsletter.module').then(m => m.NewsletterModule),
                data: {roles: [RoleNames.AUDIENCE_MANAGER, RoleNames.CONTENT_QUALITY_MANAGER, RoleNames.ADMINISTRATOR]},
                canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
            },
			{
				path: 'newsletter',
				loadChildren: () => import('./newsletter-new/newsletter.module').then((m) => m.NewsletterModule),
				data: { roles: [RoleNames.AUDIENCE_MANAGER, RoleNames.CONTENT_QUALITY_MANAGER, RoleNames.ADMINISTRATOR] },
				canActivate: [onlyDesktopActivate],
                canDeactivate: [onlyDesktopDeactivate]
			},
            {
                path: 'event',
                loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
            },
        ],
    },
    {
        path: 'google-auth',
        component: GoogleAuthComponent,
        data: {
            title: 'RocheHome - Google Auth'
        }
    },
];

const utilsPages = [
	{
		path: '',
		canActivate: [],
		component: StandardLayoutComponent,
		children: [
			{
				path: '404',
				component: NotFoundErrorComponent,
				data: { title: 'RocheHome - Not Found' },
			},
		],
	},
];

export const routes: Routes = [
	...userPages,
	...utilsPages,
	{
		path: '**',
		redirectTo: '/404',
	},
];
