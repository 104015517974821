import { Section } from '@app/newsletter-new/models/section-type.enum';
import { Block, BlockTypes } from '@app/newsletter-new/models/block-type.enum';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FormStepStatus } from '@shared/form-status-badge/form-status-badge.component';
import { FormStatus } from '@app/newsletter-new/store/recipients-form';
import {
	NewsletterSteps,
	RhNewsletterByIdResponse,
	RhNewsletterForm,
	RhNewsletterRemappingResults,
} from '@app/newsletter-new/models/newsletter';
import { RECIPIENTS_LIST_TYPE, RhRecipientsListRequestBody } from '@app/newsletter-new/models/recipients-list';
import { NewsletterValueModel } from '@app/newsletter/models';
import { RdsDialogRef } from '@rds/angular-components';
import { UserProfile } from '@core/models';
import { FormControlStatus } from '@angular/forms';
import { RhTemplateFormRequest } from '@app/newsletter-new/models/template';

export const userAgreement = createActionGroup({
	source: 'NEWSLETTER2 FORM - USER AGREEMENT',
	events: {
		openDialog: props<{
			id: number;
		}>(),
		confirmed: props<{
			id: number;
		}>(),
	},
});

export const getNewsletterById = createActionGroup({
	source: 'NEWSLETTER2 FORM - GET BY ID',
	events: {
		request: props<{
			id: number;
			useFor?: 'reuse' | 'edit' | 'create' | 'preview';
		}>(),
		success: props<{
			newsletter: RhNewsletterForm;
		}>(),
		startRemapping: props<{
			legacyNewsletter: NewsletterValueModel;
		}>(),
		remapWithSection: props<{
			legacyNewsletter: NewsletterValueModel;
			newNewsletter: RhNewsletterForm;
			missingRadaGroups: string[];
		}>(),
		remapInline: props<{
			legacyNewsletter: NewsletterValueModel;
			newNewsletter: RhNewsletterForm;
			missingRadaGroups: string[];
		}>(),
		failure: props<{
			message: string;
		}>(),
		openLegacyRemappingDialog: props<{
			newsletter: any;
		}>(),
	},
});

export const form = createActionGroup({
	source: 'NEWSLETTER2 FORM - FORM',
	events: {
		clearForm: emptyProps(),
		init: emptyProps(),
		getById: props<{
			id: number;
		}>(),
		setValue: props<{
			form: Partial<RhNewsletterForm>;
		}>(),
		setInitialValue: props<{
			form: Partial<RhNewsletterForm>;
		}>(),
		setChecked: emptyProps(),
		setStepStatus: props<{
			status: FormStepStatus;
			step: NewsletterSteps;
		}>(),
		setStepIsValid: props<{
			status: FormStatus;
			step: NewsletterSteps;
		}>(),
		setStatuses: props<{
			form: Partial<RhNewsletterForm>;
		}>(),
		setStatusesForSteps: props<{
			steps: Array<number>;
			form?: Partial<RhNewsletterForm>;
		}>(),
		setCurrentStep: props<{
			currentStep: number;
			previousStep?: number;
		}>(),
		resetCustomDisclaimer: emptyProps(),
		addFromFile: props<{
			destination: RECIPIENTS_LIST_TYPE;
			results: Array<any>;
		}>(),
		addFromSelect: props<{
			destination: RECIPIENTS_LIST_TYPE;
			results: Array<Partial<RhRecipientsListRequestBody>>;
		}>(),
		updateStatuses: props<{
			[key: string]: {
				step: string;
				form: FormControlStatus;
			};
		}>(),
		setInitialSenders: props<{
			currentUser: any;
			newsletterUser: any;
		}>()
	},
});
export const dialog = createActionGroup({
	source: 'NEWSLETTER2 FORM - DIALOG',
	events: {
		openEmptyCampaignDialog: emptyProps(),
		openFeedbackDialog: emptyProps(),
    openDisclaimerDialog: emptyProps(),
		openDialog: emptyProps(),
		check: emptyProps(),
		openSelectDialog: props<{
			campaigns: any[];
		}>(),
	},
});

export const publish = createActionGroup({
	source: 'NEWSLETTER2 FORM - PUBLISH',
	events: {
		dialog: emptyProps(),
		request: emptyProps(),
		success: emptyProps(),
		failure: props<{
			message: string;
		}>(),
	},
});

export const saveAsDraft = createActionGroup({
	source: 'NEWSLETTER2 FORM - SAVE AS DRAFT',
	events: {
		request: emptyProps(),
		success: props<{
			newsletter: Partial<RhNewsletterForm>;
		}>(),
		failure: props<{
			message: string;
		}>(),
	},
});

export const sendTest = createActionGroup({
	source: 'NEWSLETTER2 FORM - SEND TEST',
	events: {
		request: props<{
			user: UserProfile;
		}>(),
		success: emptyProps(),
		failure: props<{
			message: string;
		}>(),
		openDialog: emptyProps(),
	},
});

export const getCampaignById = createActionGroup({
	source: 'NEWSLETTER2 FORM - GET CAMPAIGN DATA',
	events: {
		request: props<{
			id: number;
		}>(),
		success: props<{
			campaign: any;
		}>(),
		failure: props<{
			message: string;
		}>(),
	},
});
export const getTemplateById = createActionGroup({
	source: 'NEWSLETTER2 FORM - GET TEMPLATE DATA',
	events: {
		request: props<{
			id: number;
			campaign: any;
		}>(),
		success: props<{
			template: any;
		}>(),
		failure: props<{
			message: string;
		}>(),
	},
});

export const preparing = createActionGroup({
	source: 'NEWSLETTER2 FORM - PREPARING',
	events: {
		start: props<{
			newsletterResponse: RhNewsletterByIdResponse;
			shouldFetchSettingsData: boolean;
			useFor?: 'reuse' | 'edit' | 'create' | 'preview';
		}>(),
		prepareContent: props<{
			newsletter: RhNewsletterForm;
			newsletterResponse: RhNewsletterByIdResponse;
			shouldFetchSettingsData: boolean;
		}>(),
		prepareContentFromTemplate: props<{
			template: RhTemplateFormRequest;
		}>(),
		prepareBlocks: props<{
			newsletter: RhNewsletterForm;
			sections: Array<Section>;
			blocks: Array<Block & { sectionId: string; subsectionId: string }>;
			shouldFetchSettingsData: boolean;
		}>(),
		fetchSettingsData: props<{
			id: string;
			blockType: BlockTypes;
			settings: any;
		}>(),
		finish: props<{
			newsletter: RhNewsletterForm;
			sections: Array<Section>;
			blocks: Array<Block & { sectionId: string; subsectionId: string }>;
		}>(),
	},
});

export const remapping = createActionGroup({
	source: 'NEWSLETTER2 FORM - REMAPPING',
	events: {
		openDecisionDialog: props<{
			legacyNewsletter: any;
		}>(),
		start: props<{
			legacyNewsletter: any;
		}>(),
		generalCheckStep: props<{
			legacyNewsletter: any;
			progressDialogRef: RdsDialogRef<any>;
		}>(),
		imageCheckStep: props<{
			legacyNewsletter: any;
			newNewsletter: RhNewsletterForm;
			progressDialogRef: RdsDialogRef<any>;
		}>(),
		radaCheckStep: props<{
			legacyNewsletter: any;
			newNewsletter: RhNewsletterForm;
			results: Partial<RhNewsletterRemappingResults>;
			progressDialogRef: RdsDialogRef<any>;
		}>(),
		usersCheckStep: props<{
			legacyNewsletter: any;
			newNewsletter: RhNewsletterForm;
			results: Partial<RhNewsletterRemappingResults>;
			progressDialogRef: RdsDialogRef<any>;
		}>(),
		sectionCheckStep: props<{
			legacyNewsletter: any;
			newNewsletter: RhNewsletterForm;
			results: Partial<RhNewsletterRemappingResults>;
			progressDialogRef: RdsDialogRef<any>;
		}>(),
		contentCheckStep: props<{
			legacyNewsletter: any;
			newNewsletter: RhNewsletterForm;
			results: Partial<RhNewsletterRemappingResults>;
			progressDialogRef: RdsDialogRef<any>;
		}>(),
		openResultDialog: props<{
			newNewsletter: RhNewsletterForm;
			sections: any;
			legacyNewsletter: any;
			results: Partial<RhNewsletterRemappingResults>;
		}>(),
		finish: emptyProps(),
	},
});
