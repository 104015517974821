import {
  GCS_SOURCES_LIST,
  GCSAdvancedFilter,
  GCSExternalDatasource,
  GCSPredefinedSource,
  GCSSearchResult,
  SearchSourceListItem
} from '@app/search/models/gcs';
import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from './cloud-search.actions';

export const feature = 'cloudSearch';

export interface ResultsState {
  results: Array<GCSSearchResult>,
  hasMoreResults: boolean,
  pageSize: number;
}

export const initResultsState = {
  results: [],
  hasMoreResults: true,
  pageSize: 10,
}

export interface State {
  noPermissions: boolean,
  mobileFiltersOpened: boolean;
  sources: Array<SearchSourceListItem>;
  currentSource: SearchSourceListItem;
  lastSource: SearchSourceListItem;
  isLoading: boolean;
  results: {
    [key: string]: ResultsState;
  }
}

export const initialState: State = {
  noPermissions: false,
  mobileFiltersOpened: false,
  sources: GCS_SOURCES_LIST,
  currentSource: GCS_SOURCES_LIST[0],
  lastSource: GCS_SOURCES_LIST[0],
  isLoading: false,
  results: {
    all: initResultsState,
    [GCSPredefinedSource.GOOGLE_GMAIL]: initResultsState,
    [GCSPredefinedSource.GOOGLE_DRIVE]: initResultsState,
    [GCSPredefinedSource.GOOGLE_SITES]: initResultsState,
    [GCSPredefinedSource.GOOGLE_GROUPS]: initResultsState,
    [GCSPredefinedSource.GOOGLE_CALENDAR]: initResultsState,
    [GCSExternalDatasource.CONNECT]: initResultsState,
    [GCSExternalDatasource.IT_LEARNING]: initResultsState,
    [GCSExternalDatasource.RECOMMENDED_LINKS]: initResultsState,
    [GCSExternalDatasource.NEURAL_NETWORK]: initResultsState,
    [GCSExternalDatasource.ROCHE_WEBSITES]: initResultsState,
    [GCSExternalDatasource.GWIZ]: initResultsState
  },
};

export const cloudSearchReducer = createReducer(
  initialState,
  on(fromActions.changeSource, (state, {source}) => ({
    ...state,
    currentSource: source
  })),
  on(fromActions.loadResults, fromActions.loadMoreResults, (state) => {
    return {
      ...state,
      isLoading: true,
    }
  }),
  on(fromActions.loadResultsSuccess, (state, {source, results, hasMoreResults}) => ({
    ...state,
    isLoading: false,
    results: {
      ...state.results,
      [source]: {
        ...state.results[source],
        results: results instanceof Array ? [
          ...results
        ] : [],
        hasMoreResults
      }
    }
  })),
  on(fromActions.loadMoreResultsSuccess, (state, {source, results, hasMoreResults}) => ({
    ...state,
    isLoading: false,
    results: {
      ...state.results,
      [source]: {
        ...state.results[source],
        results: results instanceof Array ? [
          ...state.results[source].results,
          ...results
        ] : [...state.results[source].results],
        hasMoreResults
      }
    }
  })),
  on(fromActions.changeFilters, (state, {filters}) => {
    const updatedFilters = Object.entries(state.currentSource.filters).reduce((prev, [key, object]): {
      [key: string]: GCSAdvancedFilter
    } => {
      return {
        ...prev,
        [key]: {
          ...state.currentSource.filters[key],
          selectedOption: state.currentSource.filters[key].options.find(o => o.value === filters[key])
        }
      }
    }, {} as { [key: string]: GCSAdvancedFilter });
    const updatedSources = state.sources.map(s => ({
      ...s,
      filters: s.displayName === state.currentSource.displayName ? updatedFilters : s.filters
    }));
    return {
      ...state,
      currentSource: {
        ...state.currentSource,
        filters: updatedFilters
      },
      sources: updatedSources
    }
  }),
  on(fromActions.clearSearch, (state) => ({
    ...initialState
  })),
  on(fromActions.openMobileFilters, (state) => ({
    ...state,
    mobileFiltersOpened: true,
    lastSource: state.currentSource
  })),
  on(fromActions.closeMobileFilters, (state) => ({
    ...state,
    mobileFiltersOpened: false
  })),
  on(fromActions.closeMobileFiltersAndRevert, (state) => ({
    ...state,
    mobileFiltersOpened: false,
    currentSource: state.lastSource
  })),
  on(fromActions.setNoPermissions, (state) => ({
    ...state,
    noPermissions: true
  })),
  on(fromActions.permissionGranted, (state) => ({
    ...state,
    noPermissions: false
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return cloudSearchReducer(state, action);
}

export const getSourcesList = (state: State) => state.sources;
export const getCurrentSource = (state: State) => state.currentSource;
export const getIsLoading = (state: State) => state.isLoading;
export const getMobileFiltersOpened = (state: State) => state.mobileFiltersOpened;
export const getNoPermissions = (state: State) => state.noPermissions;
