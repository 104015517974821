import {ChangeDetectorRef, Component, inject, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as fromSuggestions from '@app/root-store/suggestions';
import {
  RDS_DIALOG_DATA,
  RdsDialogRef,
  RdsSingleSelectFilterByFunc,
  RdsSingleSelectOptionComponent
} from '@rds/angular-components';
import {
  DEFAULT_PREDEFINED_QUESTION,
  DEFAULT_PREDEFINED_TITLE, EMPTY_FEEDBACK_SETTINGS,
  RhFeedbackSettings
} from '@app/newsletter-new/models/newsletter';
import {SubSink} from 'subsink';
import {distinctUntilChanged} from 'rxjs';
import {CdkDragSortEvent, moveItemInArray} from '@angular/cdk/drag-drop';
import {CustomValidators} from '@shared/form-controls/validators/validator.function';

export type SelectType = 'select' | 'autocomplete';

export interface ManageDialogData {
  title: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  feedbackSettings: RhFeedbackSettings;
}

export enum ANSWERTYPE {
  PREDEFINED = 0,
  THUMBS = 1,
  STARS = 2,
  CUSTOM = 3,
}

export type validatorsObj = {
  [key: string]: ValidatorFn | ValidatorFn[];
};

@Component({
  selector: 'rh-manage-feedback-section-dialog',
  templateUrl: './manage feedback-section-dialog.component.html',
  styleUrls: ['./manage feedback-section-dialog.component.scss'],
})
export class ManageFeedbackSectionDialogComponent implements OnDestroy, OnInit {
  private subs: SubSink = new SubSink();
  answerType = ANSWERTYPE;
  feedbackSettings: FormGroup = new FormGroup({
    feedbackType: new FormControl(null, [Validators.required]),
    contact: new FormControl(null, []),
    title: new FormControl(null, []),
    question: new FormControl(null, []),
    answers: new FormArray([new FormControl('', [])], []),
  });
  predefinedTitle: string = DEFAULT_PREDEFINED_TITLE;
  predefinedQuestion: string = DEFAULT_PREDEFINED_QUESTION;
  previewRadio = new FormControl();
  options: any[] = [
    {
      value: 0,
      name: 'Predefined',
    },
    {
      value: 1,
      name: 'Thumbs up/down',
    },
    {
      value: 2,
      name: 'Five star rating',
    },
    {
      value: 3,
      name: 'Define your answer',
    },
  ];

  public readonly rdsDialogRef =
    inject<RdsDialogRef<ManageFeedbackSectionDialogComponent>>(RdsDialogRef);
  filterBy: RdsSingleSelectFilterByFunc<string> = (
    text: string | null,
    item: RdsSingleSelectOptionComponent<string>
  ) => {
    if (text === null || text === '') {
      return true;
    } else {
      return (
        item.value.toLowerCase().includes(text.toLowerCase()) || item.id.toLowerCase().includes(text.toLowerCase())
      );
    }
  };
  private lastType = null;

  constructor(
    @Inject(RDS_DIALOG_DATA) public data: ManageDialogData,
    private store$: Store<fromSuggestions.State>,
    private cdr: ChangeDetectorRef
  ) {
    if (data.feedbackSettings && data.feedbackSettings?.answers?.length > 1) {
      for (let i = 0; i < data.feedbackSettings.answers.length - 1; i++) {
        this.addNewAnswer();
      }
    }

    this.feedbackSettings.patchValue({...data.feedbackSettings}, {emitEvent: false});
    if (data.feedbackSettings.feedbackType === this.answerType.PREDEFINED) {
      this.feedbackSettings.controls.contact.addValidators([Validators.required, Validators.email]);
      this.feedbackSettings.get('question')?.disable({onlySelf: true});
      this.feedbackSettings.get('title')?.disable({onlySelf: true});
    }
    this.lastType = data.feedbackSettings.feedbackType;
  }

  ngOnInit() {
    this.subs.sink = this.feedbackSettings
      .get('feedbackType')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        // if (this.lastType === ANSWERTYPE.PREDEFINED && value !== ANSWERTYPE.PREDEFINED) {
          this.feedbackSettings.patchValue({
            title: null,
            question: null,
            contact: null
          })
        // }

        this.lastType = value;
        switch (value) {
          case ANSWERTYPE.PREDEFINED: {
            this.feedbackSettings.controls.title.clearValidators();
            this.feedbackSettings.controls.title.setErrors(null);
            this.feedbackSettings.controls.question.clearValidators();
            this.feedbackSettings.controls.question.setErrors(null);
            this.feedbackSettings.controls.question.markAsUntouched();
            this.feedbackSettings.controls.answers.clearValidators();
            this.feedbackSettings.controls.answers.setErrors(null);
            this.feedbackSettings.controls.answers.value.map((a, index) => (this.feedbackSettings.controls.answers as FormArray).at(index).clearValidators());
            this.feedbackSettings.controls.contact.setValidators([Validators.required, Validators.email]);
            this.feedbackSettings.get('question')?.disable({onlySelf: true});
            this.feedbackSettings.get('title')?.disable({onlySelf: true});
            this.feedbackSettings.updateValueAndValidity();
            break;
          }
          case ANSWERTYPE.STARS:
          case ANSWERTYPE.THUMBS:
            this.feedbackSettings.controls.title.clearValidators();
            this.feedbackSettings.controls.title.setErrors(null);
            this.feedbackSettings.controls.contact.clearValidators();
            this.feedbackSettings.controls.contact.setErrors(null);
            this.feedbackSettings.controls.contact.markAsUntouched();
            this.feedbackSettings.controls.answers.clearValidators();
            this.feedbackSettings.controls.answers.value.map((a, index) => (this.feedbackSettings.controls.answers as FormArray).at(index).clearValidators());
            this.feedbackSettings.controls.question.setValidators([Validators.required]);
            this.feedbackSettings.controls.question.setErrors(null);
            this.feedbackSettings.controls.question.markAsUntouched();
            this.feedbackSettings.get('question')?.enable({onlySelf: true});
            this.feedbackSettings.get('title')?.enable({onlySelf: true});
            this.feedbackSettings.updateValueAndValidity();
            break;
          case ANSWERTYPE.CUSTOM: {
            this.feedbackSettings.controls.title.clearValidators()
            this.feedbackSettings.controls.title.setErrors(null);
            this.feedbackSettings.controls.contact.clearValidators();
            this.feedbackSettings.controls.contact.setErrors(null);
            this.feedbackSettings.controls.contact.markAsUntouched();
            this.feedbackSettings.controls.question.setValidators([Validators.required]);
            this.feedbackSettings.controls.question.setErrors(null);
            this.feedbackSettings.controls.question.markAsUntouched();
            this.feedbackSettings.controls.answers.setValidators([CustomValidators.maxLengthList(10), CustomValidators.minLengthList(2)]);
            this.feedbackSettings.controls.answers.value.map((a, index) => (this.feedbackSettings.controls.answers as FormArray).at(index).setValidators([Validators.required]));
            this.feedbackSettings.get('question')?.enable({onlySelf: true});
            this.feedbackSettings.get('title')?.enable({onlySelf: true});
            this.feedbackSettings.updateValueAndValidity();
            break;
          }
        }
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getAutocompletes(suggestionType, prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType, prop, phrase: event}));
  }

  addNewAnswer() {
    const answers = this.feedbackSettings.get('answers') as FormArray;
    answers.push(new FormControl('', [Validators.required]));
  }

  removeAnswer(index) {
    const answers = this.feedbackSettings.get('answers') as FormArray;
    answers.removeAt(index);
  }

  dropAnswer(event: CdkDragSortEvent) {
    console.log(event);
    const formArray = this.feedbackSettings.get('answers') as FormArray;
    moveItemInArray(
      formArray.controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  onConfirm() {
    this.feedbackSettings.enable();

    if (this.feedbackSettings.value.feedbackType === this.answerType.PREDEFINED) {
      this.feedbackSettings.patchValue({
        title: this.predefinedTitle,
        question: this.predefinedQuestion
      })
    }
    this.rdsDialogRef.close(this.feedbackSettings.value);
  }
}
