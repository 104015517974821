import {Action, createReducer, on} from '@ngrx/store';
import {FormStepStatus} from '@shared/form-status-badge/form-status-badge.component';
import {RECIPIENTS_LIST_TYPE, RhRecipientsListForm} from '@app/newsletter-new/models/recipients-list';
import * as fromActions from '@app/newsletter-new/store/recipients-form/recipients-form.actions';
import cloneDeep from '@lodash-es/cloneDeep';
import {createGuid} from '@app/shared/utils/guid';

export enum RecipientsListStepsToStatus {
    GENERAL_INFORMATION = 'generalStatus',
    RECIPIENTS_LIST = 'recipientsStatus',
    BLOCK_USERS = 'blockStatus',
}

export enum ImportStepStatus {
    IMPORT = 'IMPORT',
    MAPPING = 'MAPPING',
    PREVIEW = 'PREVIEW',
}

export type FormStatus = 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';

export interface ImportStateModel {

    initialValues: {
        [key: string]: any
    }[]

    groupedByCol: {
        [key: string]: {
            emailsPercent: number;
            values: Array<string>;
        };
    };
    step: ImportStepStatus;
    mappedData: Array<{
        lastName: string;
        firstName: string;
        email: string;
    }>;
    columnWithEmails: string;
    invalidFile: boolean;
    excludedFirstRow: boolean;
    mappingForm: any;
}

export interface State {
    form: Partial<RhRecipientsListForm>;
    initialForm: RhRecipientsListForm;
    formChecked: boolean;
    blockStatus: {
        step: FormStepStatus;
        form: FormStatus;
    };
    recipientsStatus: {
        step: FormStepStatus;
        form: FormStatus;
    };
    generalStatus: {
        step: FormStepStatus;
        form: FormStatus;
    };
    import: ImportStateModel;
    selectableRecipientLists: Array<{
        id: number;
        name: string
    }>;
}

export const initialState: State = {
    form: null,
    initialForm: null,
    formChecked: false,
    blockStatus: {
        step: FormStepStatus.NOT_DEFINED,
        form: 'INVALID',
    },
    recipientsStatus: {
        step: FormStepStatus.NOT_DEFINED,
        form: 'INVALID',
    },
    generalStatus: {
        step: FormStepStatus.NOT_DEFINED,
        form: 'INVALID',
    },
    import: {
        initialValues: null,
        mappingForm: null,
        groupedByCol: null,
        step: ImportStepStatus.IMPORT,
        mappedData: [],
        columnWithEmails: null,
        invalidFile: false,
        excludedFirstRow: false,
    },
    selectableRecipientLists: [],
};

export const recipientsFormReducer = createReducer(
    initialState,
    on(fromActions.form.setChecked, (state) => ({
        ...state,
        formChecked: true,
    })),
    on(fromActions.form.setValue, (state, {form}) => ({
        ...state,
        form: {
            ...state.form,
            ...form,
        },
    })),
    on(fromActions.form.setInitialValue, (state, {form}) => ({
        ...state,
        initialForm: cloneDeep(form),
    })),
    on(fromActions.form.updateFormRecipientList, (state, {recipientUsers}) => ({
        ...state,
        form: {
            ...state.form,
        },
    })),
    on(fromActions.form.changeStatus, (state, {status}) => ({
        ...state,
        form: {
            ...state.form,
            status,
        },
    })),
    on(fromActions.form.clear, (state) => ({
        ...initialState,
        form: null,
    })),
    // on(fromActions.form.updateGeneral, (state) => ({
    //   ...state,
    //   form: {
    //     ...state.form,
    //     recipientUsers: [...state.form.recipientUsers].map(item => ({
    //       ...item,
    //       ...isIncludedOrBlocked(item, state.form.personalization, 'recipientUsers')
    //     })),
    //   }
    // })),
    // on(fromActions.form.updateFormBlockList, (state, {personalization, blockUsers}) => ({
    //   ...state,
    //   form: {
    //     ...state.form,
    //     blockUsers: blockUsers.map(item => ({
    //       ...item,
    //       ...isIncludedOrBlocked(item, personalization, 'recipientUsers')
    //     })),
    //   }
    // })),
    on(fromActions.form.setStepStatus, (state, {status, step}) => ({
        ...state,
        [RecipientsListStepsToStatus[step]]: {
            ...state[RecipientsListStepsToStatus[step]],
            step: status,
        },
    })),
    on(fromActions.form.setStepIsValid, (state, {status, step}) => ({
        ...state,
        [RecipientsListStepsToStatus[step]]: {
            ...state[RecipientsListStepsToStatus[step]],
            form: status,
        },
    })),
    on(fromActions.form.addFromFile, (state, {destination, results}) => {
        let users = {};
        users[destination] = [...state.form[destination], ...results].map((item) => {
            return {
                ...item,
                id: createGuid(),
            };
        });
        return {
            ...state,
            form: {
                ...state.form,
                ...users,
            },
        };
    }),
    on(fromActions.form.addFromSelect, (state, {destination, results}) => {
        let key = destination === RECIPIENTS_LIST_TYPE.RECIPIENT ? 'recipients' : 'blockedRecipients';
        const mergedUsers = results?.reduce((result, item) => [...result, ...item[key]], []);

        let singleUsers = mergedUsers.filter((u) => u.type === 0).map((u) => ({...u, id: createGuid()}));
        let groups = mergedUsers.filter((u) => u.type === 1);

        let users = {};
        if (destination === RECIPIENTS_LIST_TYPE.RECIPIENT) {
            users = {
                recipientRada: [...state.form['recipientRada'], ...groups].reduce((result, item) => {
                    if (result.findIndex((r) => r.name === item.name) === -1) {
                        result.push(item);
                    }
                    return result;
                }, []),
                recipientUsers: [...state.form[destination], ...singleUsers].reduce((result, item) => {
                    if (result.findIndex((r) => r.email === item.email) === -1) {
                        result.push(item);
                    }
                    return result;
                }, []),
            };
        } else {
            users = {
                blockRada: [...state.form['blockRada'], ...groups].reduce((result, item) => {
                    if (result.findIndex((r) => r.name === item.name) === -1) {
                        result.push(item);
                    }
                    return result;
                }, []),
                blockUsers: [...state.form[destination], ...singleUsers].reduce((result, item) => {
                    if (result.findIndex((r) => r.email === item.email) === -1) {
                        result.push(item);
                    }
                    return result;
                }, []),
            };
        }
        return {
            ...state,
            form: {
                ...state.form,
                ...users,
            },
        };
    }),
    on(
        fromActions.importFile.setDataToMapping,
        (state, {groupedByCol, columnWithEmails}) => ({
            ...state,
            import: {
                ...state.import,
                step: ImportStepStatus.MAPPING,
                groupedByCol,
                columnWithEmails,
            },
        })
    ),
    on(fromActions.importFile.updateMappedData, (state, {mappedData}) => ({
        ...state,
        import: {
            ...state.import,
            mappedData,
        },
    })),
    on(fromActions.importFile.setInvalidFile, (state, {invalidFile},) => ({
        ...state,
        import: {
            ...state.import,
            invalidFile,
        },
    })),
    on(fromActions.importFile.setMappingForm, (state, {mappingForm}) => ({
        ...state,
        import: {
            ...state.import,
            mappingForm,
        },
    })),
    on(fromActions.importFile.setImportStep, (state, {step}) => ({
        ...state,
        import: {
            ...state.import,
            step,
        },
    })),
    on(fromActions.importFile.setExcludedFirstRow, (state, {excludedFirstRow}) => ({
        ...state,
        import: {
            ...state.import,
            excludedFirstRow,
        },
    })),
    on(fromActions.importFile.clearImport, (state) => ({
        ...state,
        import: {
            ...initialState.import,
        },
    })),
    on(fromActions.importFile.setInitialValues, (state, {initialValues}) => ({
        ...state,
        import: {
            ...initialState.import,
            initialValues,
        },
    })),
    on(fromActions.importExistingList.success, (state, {lists}) => ({
        ...state,
        selectableRecipientLists: lists,
    })),
    on(fromActions.importExistingList.clear, (state) => ({
        ...state,
        selectableRecipientLists: [],
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return recipientsFormReducer(state, action);
}

export const getForm = (state: State) => state.form;

export const getFormChecked = (state: State) => state.formChecked;
export const getInitialForm = (state: State) => state.initialForm;

export const getRecipientsStepStatus = (state: State) => state.recipientsStatus.step;
export const getRecipientsFormStatus = (state: State) => state.recipientsStatus.form;

export const getBlockStepStatus = (state: State) => state.blockStatus.step;
export const getBlockFormStatus = (state: State) => state.blockStatus.form;

export const getGeneralInformationStepStatus = (state: State) => state.generalStatus.step;
export const getGeneralInformationFormStatus = (state: State) => state.generalStatus.form;

export const getImport = (state: State) => state.import;
export const getInitialValues = (state: ImportStateModel) => state.initialValues;
export const getMappingForm = (state: ImportStateModel) => state.mappingForm;
export const getSelectableRecipientLists = (state: State) => state.selectableRecipientLists;
