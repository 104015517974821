import { Injectable } from '@angular/core';
import { NewsboardAuthService } from '@app/core/auth/services/newsboard-auth.service';
import { Pagination } from '@app/core/models';
import { TemplatesListType, TemplatesRefresh, TemplateUpdate } from '@app/core/models/newsboard/template';
import * as fromRouter from '@app/root-store/router';
import * as fromBackButton from '@app/root-store/ui/back-button';
import { ConfirmDialogComponent, ConfirmDialogData } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { RenameDialogComponent, RenameDialogData } from '@app/shared/dialogs/rename-dialog/rename-dialog.component';
import {
	SendCopyDialogComponent,
	SendCopyDialogData,
} from '@app/shared/dialogs/send-copy-dialog/send-copy-dialog.component';
import {
	ShareTemplateDialogComponent,
	ShareTemplateDialogData,
} from '@app/shared/dialogs/share-template-dialog/share-template-dialog.component';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { RdsDialogService } from '@rds/angular-components';
import { catchError, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import * as fromActions from './templates.actions';
import * as fromReducer from './templates.reducer';
import * as fromSelectors from './templates.selectors';
import * as fromTemplateForm from '../template-form';
import * as fromNewsletterBlocks from '../newsletter-blocks';
import { NewsletterService } from '@core/services/newsletter.service';
import {
	TemplatePreviewDialogComponent,
	TemplatePreviewDialogData,
} from '@app/newsletter-new/dialogs/template-preview-dialog/template-preview-dialog.component';
import { USER_ROLES_TEMPLATE } from '@app/newsletter-new/models/user-roles.enum';

@Injectable()
export class TemplatesEffects {
	public initTemplates$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.lists.init),
			mergeMap(() => [
				fromActions.lists.getMy({ refresh: TemplatesRefresh.NONE }),
				fromActions.lists.getShared({ refresh: TemplatesRefresh.NONE }),
				fromActions.lists.getReadonly({ refresh: TemplatesRefresh.NONE }),
				fromActions.lists.getPredefined({ refresh: TemplatesRefresh.NONE }),
				fromActions.lists.getSentToMe({ refresh: TemplatesRefresh.NONE }),
			])
		)
	);

	public useTemplate$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.useTemplate),
				mergeMap(({ id, listType }) => [
					fromBackButton.set({
						label: 'Create Newsletter',
						routeBack: 'newsletter/newsletter/create',
						routeCurrent: `/newsletter/newsletter/create/inline?useTemplate=${id}`,
						routeBackQueryParams: { activeTab: listType },
					}),
					fromRouter.go({ path: `/newsletter/newsletter/create/inline`, queryParams: { useTemplate: id } }),
				])
			),
		{ dispatch: true }
	);
	public openTemplatePreview$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.preview.dialog),
				withLatestFrom(this.store$.pipe(select(fromTemplateForm.selectCreateFormForRequest))),
				map(([{ id, backButton }, template]) => {
					const simpleUser = this.auth.currentSimpleUser;
					const isAdmin = this.auth.isAdmin || this.auth.isSuperAdmin;
					const canEdit =
						isAdmin ||
						!!template.newsletterTemplateUserRole.find(
							(u) => u.identifier === simpleUser.identifier && u.role === USER_ROLES_TEMPLATE.EDITOR
						);

					const data: TemplatePreviewDialogData = {
						form: this.store$.pipe(select(fromTemplateForm.selectCreateFormForRequest)),
						sections: this.store$.pipe(select(fromNewsletterBlocks.selectSections)),
						backButton,
						buttons: {
							editTemplate: {
								disabled: !canEdit,
								visible: !template.isPredefined && canEdit,
							},
						},
					};
					const dialog = this.dialogService.open(TemplatePreviewDialogComponent, {
						data,
					});
					return { dialog, template };
				}),
				switchMap(({ dialog, template }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						mergeMap(({ url, error }) => {
							return [];
						})
					)
				)
			),
		{ dispatch: true }
	);

	public getMy$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.lists.getMy),
			withLatestFrom(this.store$.pipe(select(fromSelectors.selectMyTemplatesPagination))),
			map(([{ refresh, pageSize }, pagination]) => {
				const isTotalLowerThanPageSize = pagination.totalCount < pagination.pageSize;
				const shouldAdd = refresh === TemplatesRefresh.REFRESH_PLUS;
				if (pageSize) {
					pagination = {
						...pagination,
						pageSize,
					};
				}
				console.log(pagination);
				return {
					isTotalLowerThanPageSize,
					shouldAdd,
					refresh,
					pagination:
						refresh > 0
							? {
									...Pagination,
									pageIndex: 0,
									pageSize: isTotalLowerThanPageSize
										? pagination.totalCount + (shouldAdd ? 1 : 0)
										: (pagination.pageIndex + 1) * pagination.pageSize,
								}
							: pagination,
				};
			}),
			switchMap(({ refresh, pagination, isTotalLowerThanPageSize, shouldAdd }) =>
				this.newsletterService.getTemplatesList(pagination, 'mytemplates').pipe(
					mergeMap(({ data, pagination }) => [
						fromActions.lists.success({ listType: 'mytemplates', data, pagination, refresh }),
						fromActions.lists.incrementTotalCount({
							listType: 'mytemplates',
							by: isTotalLowerThanPageSize && shouldAdd ? 1 : 0,
						}),
					]),
					catchError((error) => of(fromActions.lists.failure({ listType: 'mytemplates', error })))
				)
			)
		)
	);
	public getShared$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.lists.getShared),
			withLatestFrom(this.store$.pipe(select(fromSelectors.selectSharedTemplatesPagination))),
			map(([{ refresh, pageSize }, pagination]) => {
				const isTotalLowerThanPageSize = pagination.totalCount < pagination.pageSize;
				const shouldAdd = refresh === TemplatesRefresh.REFRESH_PLUS;
				if (pageSize) {
					pagination = {
						...pagination,
						pageSize,
					};
				}
				return {
					isTotalLowerThanPageSize,
					shouldAdd,
					refresh,
					pagination:
						refresh > 0
							? {
									...Pagination,
									pageIndex: 0,
									pageSize: isTotalLowerThanPageSize
										? pagination.totalCount + (shouldAdd ? 1 : 0)
										: (pagination.pageIndex + 1) * pagination.pageSize,
								}
							: pagination,
				};
			}),
			switchMap(({ refresh, pagination, isTotalLowerThanPageSize, shouldAdd }) =>
				this.newsletterService.getTemplatesList(pagination, 'shared').pipe(
					mergeMap(({ data, pagination }) => [
						fromActions.lists.success({ listType: 'shared', data, pagination, refresh }),
						fromActions.lists.incrementTotalCount({
							listType: 'shared',
							by: isTotalLowerThanPageSize && shouldAdd ? 1 : 0,
						}),
					]),
					catchError((error) => of(fromActions.lists.failure({ listType: 'shared', error })))
				)
			)
		)
	);
	public getReadonly$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.lists.getReadonly),
			withLatestFrom(this.store$.pipe(select(fromSelectors.selectReadonlyTemplatesPagination))),
			map(([{ refresh, pageSize }, pagination]) => {
				const isTotalLowerThanPageSize = pagination.totalCount < pagination.pageSize;
				const shouldAdd = refresh === TemplatesRefresh.REFRESH_PLUS;
				if (pageSize) {
					pagination = {
						...pagination,
						pageSize,
					};
				}
				return {
					isTotalLowerThanPageSize,
					shouldAdd,
					refresh,
					pagination:
						refresh > 0
							? {
									...Pagination,
									pageIndex: 0,
									pageSize: isTotalLowerThanPageSize
										? pagination.totalCount + (shouldAdd ? 1 : 0)
										: (pagination.pageIndex + 1) * pagination.pageSize,
								}
							: pagination,
				};
			}),
			switchMap(({ refresh, pagination, isTotalLowerThanPageSize, shouldAdd }) =>
				this.newsletterService.getTemplatesList(pagination, 'readonly').pipe(
					mergeMap(({ data, pagination }) => [
						fromActions.lists.success({ listType: 'readonly', data, pagination, refresh }),
						fromActions.lists.incrementTotalCount({
							listType: 'readonly',
							by: isTotalLowerThanPageSize && shouldAdd ? 1 : 0,
						}),
					]),
					catchError((error) => of(fromActions.lists.failure({ listType: 'readonly', error })))
				)
			)
		)
	);

	public getAllTemplates$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.lists.getAllTemplates),
			switchMap(() =>
				this.newsletterService.getTemplatesListAll({ pageSize: 1000, pageIndex: 0 } as Pagination).pipe(
					mergeMap(({ data, pagination }) => []),
					catchError((error) => of(fromActions.lists.failure({ listType: 'predefined', error })))
				)
			)
		)
	);
	public getPredefined$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.lists.getPredefined),
			withLatestFrom(this.store$.pipe(select(fromSelectors.selectPredefinedPagination))),
			map(([{ refresh, pageSize }, pagination]) => {
				const isTotalLowerThanPageSize = pagination.totalCount < pagination.pageSize;
				const shouldAdd = refresh === TemplatesRefresh.REFRESH_PLUS;
				if (pageSize) {
					pagination = {
						...pagination,
						pageSize,
					};
				}

				return {
					isTotalLowerThanPageSize,
					shouldAdd,
					refresh,
					pagination:
						refresh > 0
							? {
									...Pagination,
									pageIndex: 0,
									pageSize: isTotalLowerThanPageSize
										? pagination.totalCount + (shouldAdd ? 1 : 0)
										: (pagination.pageIndex + 1) * pagination.pageSize,
								}
							: pagination,
				};
			}),
			switchMap(({ refresh, pagination, isTotalLowerThanPageSize, shouldAdd }) =>
				this.newsletterService.getTemplatesList(pagination, 'predefined').pipe(
					mergeMap(({ data, pagination }) => [
						fromActions.lists.success({ listType: 'predefined', data, pagination, refresh }),
						fromActions.lists.incrementTotalCount({
							listType: 'predefined',
							by: isTotalLowerThanPageSize && shouldAdd ? 1 : 0,
						}),
					]),
					catchError((error) => of(fromActions.lists.failure({ listType: 'predefined', error })))
				)
			)
		)
	);

	public getSentToMe$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.lists.getSentToMe),
			withLatestFrom(this.store$.pipe(select(fromSelectors.selectSentToMeTemplatesPagination))),
			map(([{ refresh }, pagination]) => {
				const isTotalLowerThanPageSize = pagination.totalCount < pagination.pageSize;
				const shouldAdd = refresh === TemplatesRefresh.REFRESH_PLUS;
				return {
					isTotalLowerThanPageSize,
					shouldAdd,
					refresh,
					pagination:
						refresh > 0
							? {
									...Pagination,
									pageIndex: 0,
									pageSize: isTotalLowerThanPageSize
										? pagination.totalCount + (shouldAdd ? 1 : 0)
										: (pagination.pageIndex + 1) * pagination.pageSize,
								}
							: pagination,
				};
			}),
			switchMap(({ refresh, pagination, isTotalLowerThanPageSize, shouldAdd }) =>
				this.newsletterService.getTemplatesList(pagination, 'senttome').pipe(
					mergeMap(({ data, pagination }) => [
						fromActions.lists.success({ listType: 'senttome', data, pagination, refresh }),
						fromActions.lists.incrementTotalCount({
							listType: 'senttome',
							by: isTotalLowerThanPageSize && shouldAdd ? 1 : 0,
						}),
					]),
					catchError((error) => of(fromActions.lists.failure({ listType: 'senttome', error })))
				)
			)
		)
	);
	public deleteTemplateRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.deleteTemplate.request),
			switchMap(({ id, listType }) =>
				this.newsletterService.deleteTemplate(id).pipe(
					map(() => fromActions.deleteTemplate.success({ id, listType })),
					catchError((message) => of(fromActions.deleteTemplate.failure({ message })))
				)
			)
		)
	);

	public deleteTemplateSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.deleteTemplate.success),
			map(({ listType }) => {
				switch (listType) {
					case 'mytemplates':
						return fromActions.lists.getMy({ refresh: TemplatesRefresh.REFRESH });
					case 'shared':
						return fromActions.lists.getShared({ refresh: TemplatesRefresh.REFRESH });
					case 'senttome':
						return fromActions.lists.getSentToMe({ refresh: TemplatesRefresh.REFRESH });
					case 'predefined':
						return fromActions.lists.getPredefined({ refresh: TemplatesRefresh.REFRESH });
				}
			})
		)
	);

	public openDeleteTemplateDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.deleteTemplate.dialog),
				map(({ id, listType }) => {
					let title = '';
					let messages = [];
					switch (listType) {
						case 'mytemplates': {
							(title = 'Delete this template?'), messages.push('It will not be visible in My templates any more.');
							break;
						}
						case 'predefined': {
							(title = 'Delete predefined template?'),
								messages.push('It will not be visible in Predefined templates any more.');
							break;
						}
						case 'shared': {
							(title = 'Delete shared template?'),
								messages.push('It will not be visible in Shared templates for <strong>all enabled users.</strong>');
							break;
						}
					}
					const data: ConfirmDialogData = {
						ids: [id],
						title,
						messages,
						cancelButtonLabel: `Cancel`,
						confirmButtonLabel: `Delete template`,
						confirmButtonType: 'warning',
					};
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						size: 'm',
						data,
					});
					return { id, listType, dialog };
				}),
				switchMap(({ id, listType, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data) => fromActions.deleteTemplate.request({ id: data.ids[0], listType }))
					)
				)
			),
		{ dispatch: true }
	);

	public openShareTemplateDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.shareTemplate.dialog),
				map(({ template, listType }) => {
					template = {
						...template,
						owners: template.newsletterTemplateUserRole
							.filter((i) => i.role === USER_ROLES_TEMPLATE.OWNER)
							.map((i) => ({ ...i, identifier: i.userLogin })),
						editors: template.newsletterTemplateUserRole
							.filter((i) => i.role === USER_ROLES_TEMPLATE.EDITOR)
							.map((i) => ({ ...i, identifier: i.userLogin })),
						viewers: template.newsletterTemplateUserRole
							.filter((i) => i.role === USER_ROLES_TEMPLATE.VIEWER)
							.map((i) => ({ ...i, identifier: i.userLogin })),
					};
					const data: ShareTemplateDialogData = {
						template,
					};
					const dialog = this.dialogService.open(ShareTemplateDialogComponent, {
						size: 'l',
						data,
					});
					return { template, listType, dialog };
				}),
				switchMap(({ template, listType, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map(({ value, isMyTemplate, initialUsersNotChanged, initialRolesNotChanged }) => {
							const simpleUser = this.auth.currentSimpleUser;
							let listsToRefresh: Array<{
								list: TemplatesListType;
								refresh: TemplatesRefresh;
							}> = [];

							const sumOldUsers = template.owners.length + template.editors.length + template.viewers.length;
							const sumNewUsers = value.owners.length + value.editors.length + value.viewers.length;
							const fromMyToShared = sumOldUsers === 1 && sumNewUsers > 1;
							const fromSharedToMy = sumOldUsers > 1 && sumNewUsers === 1;
							const isViewer = value.viewers.find((u) => u.identifier === simpleUser.identifier);
							if (fromMyToShared || fromSharedToMy) {
								listsToRefresh = [
									{
										list: 'mytemplates',
										refresh: fromMyToShared ? TemplatesRefresh.REFRESH : TemplatesRefresh.REFRESH_PLUS,
									},
									{
										list: 'shared',
										refresh: fromSharedToMy
											? TemplatesRefresh.REFRESH
											: isViewer
												? TemplatesRefresh.REFRESH
												: TemplatesRefresh.REFRESH_PLUS,
									},
									{
										list: 'senttome',
										refresh: fromSharedToMy
											? TemplatesRefresh.REFRESH
											: isViewer
												? TemplatesRefresh.REFRESH_PLUS
												: TemplatesRefresh.REFRESH,
									},
								];
							} else if (isViewer) {
								listsToRefresh = [
									{ list: listType, refresh: TemplatesRefresh.REFRESH },
									{ list: 'senttome', refresh: TemplatesRefresh.REFRESH_PLUS },
								];
							} else {
								listsToRefresh = [{ list: listType, refresh: TemplatesRefresh.REFRESH }];
							}
							const templateUpdate: Partial<TemplateUpdate> = {
								newsletterTemplateUserRole: [
									...value?.owners?.map((i) => ({ ...i, userLogin: i.identifier, role: USER_ROLES_TEMPLATE.OWNER })),
									...value?.editors?.map((i) => ({ ...i, userLogin: i.identifier, role: USER_ROLES_TEMPLATE.EDITOR })),
									...value?.viewers?.map((i) => ({ ...i, userLogin: i.identifier, role: USER_ROLES_TEMPLATE.VIEWER })),
								],
							};
							let updateContext = '';

							if (
								(isMyTemplate && !initialRolesNotChanged && !initialRolesNotChanged) ||
								(!isMyTemplate && initialUsersNotChanged && !initialRolesNotChanged)
							) {
								updateContext = 'permissionsUpdated';
							}
							if (!isMyTemplate && !initialRolesNotChanged && !initialRolesNotChanged) {
								updateContext = 'sharedAndPermissionsUpdated';
							}
							if (!isMyTemplate && !initialRolesNotChanged && initialRolesNotChanged) {
								updateContext = 'shared';
							}
							return {
								id: template.id,
								users: templateUpdate.newsletterTemplateUserRole,
								listType,
								listsToRefresh,
								updateContext,
							};
						})
					)
				),
				map(({ id, users, listType, listsToRefresh, updateContext }) =>
					fromActions.shareTemplate.request({ id, users, listsToRefresh, listType, updateContext })
				)
			),
		{ dispatch: true }
	);

	public shareTemplateRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.shareTemplate.request),
			switchMap(({ id, users, listsToRefresh, listType, updateContext }) =>
				this.newsletterService.shareTemplate({ id, users }).pipe(
					map(() => fromActions.updateTemplate.success({ listsToRefresh, listType, updateContext })),
					catchError((error) => of(fromActions.shareTemplate.failure({ error })))
				)
			)
		)
	);

	public openSendCopyDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.sendCopy.dialog),
				map(({ template }) => {
					const data: SendCopyDialogData = {
						title: 'Send template copy',
						message: 'Template will appear in recipients "My templates" tab.',
						confirmButtonLabel: 'Send template',
					};
					const dialog = this.dialogService.open(SendCopyDialogComponent, {
						size: 'l',
						data,
					});
					return { template, dialog };
				}),
				switchMap(({ template, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((users) => {
							return { id: template.id, users };
						})
					)
				),
				map(({ id, users }) => fromActions.sendCopy.request({ id, users }))
			),
		{ dispatch: true }
	);

	public sendTemplateCopyRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.sendCopy.request),
			switchMap(({ id, users }) =>
				this.newsletterService.sendTemplateCopy({ id, users }).pipe(
					map(() => fromActions.sendCopy.success()),
					catchError((error) => of(fromActions.sendCopy.failure({ error })))
				)
			)
		)
	);
	public acceptTemplateCopyRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.acceptSentToMe.request),
			switchMap(({ template }) =>
				this.newsletterService.acceptTemplateCopy(template.id).pipe(
					map(() => fromActions.acceptSentToMe.success()),
					catchError((error) => of(fromActions.acceptSentToMe.failure({ error })))
				)
			)
		)
	);
	public rejectTemplateCopyRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.rejectSentToMe.request),
			switchMap(({ template }) =>
				this.newsletterService.rejectTemplateCopy(template.id).pipe(
					map(() => fromActions.rejectSentToMe.success()),
					catchError((error) => of(fromActions.rejectSentToMe.failure({ error })))
				)
			)
		)
	);

	public openRenameDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.renameTemplate.dialog),
				map(({ template, listType }) => {
					const data: RenameDialogData = {
						title: 'Rename Template',
						oldName: template['displayName'] || template.title,
						controlLabel: 'Title of the Template',
						confirmButtonLabel: 'Rename Template',
						required: true,
						maxLength: 100,
					};
					const dialog = this.dialogService.open(RenameDialogComponent, {
						size: 'l',
						data,
					});
					return { template, listType, dialog };
				}),
				switchMap(({ template, listType, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((title) => {
							return { id: template.id, title, listType };
						})
					)
				),
				map(({ id, title, listType }) =>
					fromActions.renameTemplate.request({
						id,
						title,
						listType,
						listsToRefresh: [{ list: listType, refresh: TemplatesRefresh.REFRESH }],
						updateContext: 'renamed',
					})
				)
			),
		{ dispatch: true }
	);
	public renameRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.renameTemplate.request),
			switchMap(({ id, title, listType, listsToRefresh, updateContext }) =>
				this.newsletterService.renameTemplate(id, title).pipe(
					map(() => fromActions.updateTemplate.success({ listType, listsToRefresh, updateContext })),
					catchError((error) => of(fromActions.updateTemplate.failure({ error })))
				)
			)
		)
	);

	// public updateTemplateRequest$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(fromActions.updateTemplate.request),
	// 		switchMap(({ id, templateUpdate, listType, listsToRefresh, updateContext }) =>
	// 			this.newsletterService.updateTemplate(id, templateUpdate).pipe(
	// 				map(() => fromActions.updateTemplate.success({ listType, listsToRefresh, updateContext })),
	// 				catchError((error) => of(fromActions.updateTemplate.failure({ error })))
	// 			)
	// 		)
	// 	)
	// );

	public updateTemplateSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.updateTemplate.success),
			mergeMap(({ listType, listsToRefresh }) => {
				const actions = [];
				listsToRefresh.forEach((l) => {
					switch (l.list) {
						case 'mytemplates':
							actions.push(fromActions.lists.getMy({ refresh: l.refresh }));
							break;
						case 'shared':
							actions.push(fromActions.lists.getShared({ refresh: l.refresh }));
							break;
						case 'senttome':
							actions.push(fromActions.lists.getSentToMe({ refresh: l.refresh }));
							break;
						case 'readonly':
							return fromActions.lists.getReadonly({ refresh: TemplatesRefresh.NONE });
						case 'predefined':
							actions.push(fromActions.lists.getPredefined({ refresh: l.refresh }));
							break;
					}
				});
				return actions;
			})
		)
	);

	public acceptRejectSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.acceptSentToMe.success, fromActions.rejectSentToMe.success),
			mergeMap(() => [
				fromActions.lists.getMy({ refresh: TemplatesRefresh.REFRESH_PLUS }),
				fromActions.lists.getSentToMe({ refresh: TemplatesRefresh.REFRESH }),
			])
		)
	);

	public loadMore$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.lists.loadMore),
			map(({ listType }) => {
				switch (listType) {
					case 'mytemplates':
						return fromActions.lists.getMy({ refresh: TemplatesRefresh.NONE });
					case 'shared':
						return fromActions.lists.getShared({ refresh: TemplatesRefresh.NONE });
					case 'senttome':
						return fromActions.lists.getSentToMe({ refresh: TemplatesRefresh.NONE });
					case 'readonly':
						return fromActions.lists.getReadonly({ refresh: TemplatesRefresh.NONE });
					case 'predefined':
						return fromActions.lists.getPredefined({ refresh: TemplatesRefresh.NONE });
				}
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromReducer.State>,
		// private newsletterTemplateService: NewsletterTemplatesService,
		private newsletterService: NewsletterService,
		private auth: NewsboardAuthService,
		private dialogService: RdsDialogService
	) {}
}
