import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RDS_DIALOG_DATA } from '@rds/angular-components';
import { debounceTime } from 'rxjs';

export interface ConfirmDialogData {
	// TODO: change ids to something more self explanated
	ids?: Array<number | string>;
	title: string;
	messages: Array<string>;
	confirmationReason?: {
		label: string;
		required?: boolean;
		value?: string;
		maxLength?: number;
	};
	confirmationOptions?: {
		options: Array<{
			label: string;
			value: any;
			disabled: boolean;
			reason?: string;
		}>;
		value?: any;
	};
	hideCancelButton?: boolean;
	cancelButtonLabel?: string;
	confirmButtonLabel?: string;
	confirmButtonType?: 'warning' | 'primary';
}

@Component({
	selector: 'rh-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
	confirmation: FormGroup = new FormGroup({});

	constructor(@Inject(RDS_DIALOG_DATA) public data: ConfirmDialogData) {
		if (!!this.data.confirmationReason) {
			this.confirmation.addControl('reason', new FormControl(''));
			if (this.data.confirmationReason.required) {
				this.confirmation.controls.reason.addValidators(Validators.required);
			}
			if (this.data.confirmationReason.maxLength) {
				this.confirmation.controls.reason.addValidators(Validators.maxLength(this.data.confirmationReason.maxLength || 255));
			}
      if (this.data.confirmationReason.value) {
				this.confirmation.controls.reason.patchValue(this.data.confirmationReason.value);
			}
			this.confirmation.controls.reason.valueChanges.pipe(debounceTime(200)).subscribe((value) => {
				this.data.confirmationReason.value = value;
			});
		}
		if (!!this.data.confirmationOptions) {
			this.confirmation.addControl('option', new FormControl(null, [Validators.required]));

			this.confirmation.controls.option.valueChanges.pipe(debounceTime(200)).subscribe((value) => {
				this.data.confirmationOptions.value = value;
			});
		}
	}
}
