<div rds-dialog-content class="p-7">
	<p class="ui-label-l-bold">Saving...</p>
	<rds-progress-bar class="mb-3 mt-5"></rds-progress-bar>
	<p class="ui-body-m mb-5">It may take up to 60 seconds</p>
	<div class="preview__wrapper">
		<div #image class="preview__content">
			<rh-newsletter-preview-readonly
        rhTemplateSkeleton
				[sections]="data.sections | async"
				[preview]="data.form | async"
			></rh-newsletter-preview-readonly>
		</div>
	</div>
</div>
<div class="media-placeholder">
	<div #imagePlaceholder class="bg-placeholder">
		<rds-icon icon="image" namespace="outlined" style="width: 80px; height: 80px"></rds-icon>
	</div>
</div>
<div class="media-placeholder">
	<div #videoPlaceholder class="bg-placeholder">
		<rds-icon icon="video" namespace="outlined" style="width: 80px; height: 80px"></rds-icon>
	</div>
</div>
